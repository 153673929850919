import React from "react"
import { Container, Grid, Typography } from "@mui/material"
import Layout from "../components/Layout/Layout"
import PageTitle from "../components/PageTitle/PageTitle"
import { Helmet } from "react-helmet"

const Copyright = () => {
  return (
    <Layout>
      <Helmet>
        <title>Copyright - Cassandra Link</title>
        <meta property="og:image" content="../images/Logo.svg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content={"Copyright - Cassandra Link"} />
        <meta name="description" content="This is the copyright page of Cassandra Link. Find information about copyright, intellectual property, and legal notices." />
  <meta property="og:description" content="This is the copyright page of Cassandra Link. Find information about copyright, intellectual property, and legal notices." />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Cassandra Link",
            keywords: "Copyright - Cassandra Link",
            author: {
              "@type": "Organization",
              name: "Copyright, Cassandra Link, Cassandra",
            },
          })}
        </script>
        {/* Open Graph */}
        <meta property="og:title" content={"Copyright - Cassandra Link"} />

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"Copyright - Cassandra Link"} />
        <meta name="twitter:image" content={"../../images/Logo.svg"} />
      </Helmet>
      <Container maxWidth="lg">
        <Grid className="content">
          <PageTitle title="Trademark Notice" />
          <Typography paragraph>
            All product names, logos, and brands are property of their
            respective owners. All company, product and service names used in
            this website are for identification purposes only. Use of these
            names, logos, and brands does not imply endorsement.
          </Typography>
          <Typography paragraph>
            Apache, Apache Cassandra, Cassandra, Apache Lucene, Lucene, Apache
            Solr, Apache Hadoop, Hadoop, Apache Spark, Spark, Apache Kafka and
            Kafka are either registered trademarks or trademarks of the Apache
            Software Foundation or its subsidiaries in the United States and/or
            other countries.
          </Typography>
          <Typography paragraph>
            DataStax, Titan, and TitanDB are registered trademarks of DataStax,
            Inc. and its subsidiaries in the United States and/or other
            countries.
          </Typography>
          <Typography paragraph>
            YugaByte is a registered trademark of Yugabyte, Inc. and its
            subsidiaries in the United States and/or other countries.
          </Typography>
          <Typography paragraph>
            ScyllaDB is a registered trademark of Scylla, Inc. and its
            subsidiaries in the United States and/or other countries.
          </Typography>
          <Typography paragraph>
            CosmosDB is a registered trademark of Microsoft Corporation and its
            subsidiaries in the United States and/or other countries.
          </Typography>
          <Typography paragraph>
            Other trademarks and trade names may be used in this document to
            refer to either the entities claiming the marks and/or names or
            their products and are the property of their respective owners. We
            disclaims proprietary interest in the marks and names of others.
          </Typography>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Copyright
